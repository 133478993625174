/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import styles from "./AcUpdatePanel.module.css";
import { Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext/AuthContext";
import { signOut } from "firebase/auth";

function AcUpdatePanel() {
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState([]);
    const [editedData, setEditedData] = useState([]);
    const [title, setTitle] = useState("");

    const { service } = useParams();
    useEffect(() => {
        if (service === 'AcService') setTitle('AC Service Details');
        if (service === 'AcGasFilling') setTitle('AC Gas Filling Services');
        if (service === 'AcRepairing') setTitle('AC Repairing Services');
        if (service === 'AcCoating') setTitle('AC Coating Services');
        if (service === 'AcOffer') setTitle('AC Combo Offers');
        if (service === 'AcOnRent') setTitle('AC On Rent');

    }, []);
    function sortObjectsDescending(arr) {
        return arr.sort((a, b) => {
            const aKey = a.id;
            const bKey = b.id;

            if (aKey === "window") return -1;
            if (bKey === "window") return 1;

            if (aKey === "split") return -1;
            if (bKey === "split") return 1;

            if (aKey === "cassette") return -1;
            if (bKey === "cassette") return 1;

            return 0;
        });
    }

    // Fetch data from Firebase when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            const docRef = collection(db, service);
            const querySnapshot = await getDocs(docRef);
            const docs = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            sortObjectsDescending(docs);
            setData(docs);
            setEditedData(docs);
        };

        fetchData();
    }, []);

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleSaveClick = async () => {
        // Update the edited data on Firebase
        try {
            for (const item of editedData) {
                const itemRef = doc(db, service, item.id);
                await updateDoc(itemRef, item);
            }
            setEditMode(false);
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    const handleCancelClick = () => {
        setEditMode(false);
        // Reset the edited data to the original data from Firebase
        setEditedData(data);
    };

    const handleServiceChange = (id, service, fieldIndex, value) => {
        const updatedData = editedData.map((item) => {
            if (item.id === id) {
                item[service][fieldIndex] = value;
            }
            return item;
        });
        setEditedData(updatedData);
    };
    const { setEmail } =
        useContext(AuthContext);

    const navigate = useNavigate();
    const logoutHandler = async () => {
        // eslint-disable-next-line no-unused-vars
        const res = await signOut(auth);
        navigate('/admin');
        setEmail('');
    }


    return (
        <div className={styles.cont}>
            <div className={styles.navbar}>
                <h1>Admin Dashboard</h1>
                <div className={styles.buttonCont}>
                    <Button variant='outlined' color='secondary'><Link to='/admin/link' target='_blank'>Generate link</Link></Button>
                    <Button variant='outlined' color='primary' onClick={()=>navigate('/admin/dashboard')}>Back</Button>
                    <Button variant='outlined' color='error' onClick={logoutHandler}>Logout</Button>
                </div>

            </div>
            <div className={styles.heading}>
                <h1 className={styles.heading}>{title}</h1>
                {!editMode && (<Button variant="contained" color="secondary" onClick={handleEditClick}>Edit</Button>)}
                {editMode && (
                    <div className={styles.buttonCont}>
                        <Button variant="contained" color="success" onClick={handleSaveClick}>Save</Button>
                        <Button variant="contained" color="error" onClick={handleCancelClick}>Cancel</Button>
                    </div>
                )}
            </div>
            {editMode ? (
                <div>
                    {editedData.map((ac) => (
                        <div key={ac.id} className={styles.card}>
                            <h2>
                                {ac.id === "split" && "Split AC"}
                                {ac.id === "window" && "Window AC"}
                                {ac.id !== "split" && ac.id !== 'window' && "Cassette AC"}
                            </h2>
                            <ul>
                                {Object.entries(ac).map(
                                    ([serviceName, [price, discount, image]]) => (

                                        <div key={serviceName}>
                                            {serviceName.length > 3 && (
                                                <li>
                                                    <div className={styles.flexRow}><input type="text" value={serviceName} readOnly />
                                                        <input
                                                            type="number"
                                                            value={price}
                                                            placeholder="Price"
                                                            onChange={(e) =>
                                                                handleServiceChange(
                                                                    ac.id,
                                                                    serviceName,
                                                                    0,
                                                                    parseFloat(e.target.value)
                                                                )
                                                            }
                                                        />
                                                        <input
                                                            type="number"
                                                            value={discount}
                                                            placeholder="Discount"
                                                            onChange={(e) =>
                                                                handleServiceChange(
                                                                    ac.id,
                                                                    serviceName,
                                                                    1,
                                                                    parseFloat(e.target.value)
                                                                )
                                                            }
                                                        />
                                                        <input
                                                            type="text"
                                                            value={image || ""}
                                                            placeholder="Image Link"
                                                            onChange={(e) =>
                                                                handleServiceChange(
                                                                    ac.id,
                                                                    serviceName,
                                                                    2,
                                                                    e.target.value
                                                                )
                                                            }
                                                        /></div>
                                                    <img className={styles.displayImage} src={image} alt={serviceName} />
                                                </li>)}
                                        </div>
                                    )
                                )}
                            </ul>
                        </div>
                    ))}

                </div>
            ) : (
                <div>
                    {data.map((ac) => (
                        <div key={ac.id} className={styles.card}>
                            <h2>
                                {ac.id === "split" && "Split AC"}
                                {ac.id === "window" && "Window AC"}
                                {ac.id !== "split" && ac.id !== 'window' && "Cassette AC"}
                            </h2>{" "}
                            <ul>
                                {Object.entries(ac).map(
                                    ([serviceName, [price, discount, image]]) => (
                                        <div key={serviceName}>
                                            {serviceName.length > 3 && (

                                                <li>
                                                    <div className={styles.flexCol}>
                                                        <div>
                                                            <strong>Name:</strong> {serviceName}
                                                        </div>
                                                        <div>
                                                            <strong>Price:</strong> ₹{price}
                                                        </div>
                                                        <div>
                                                            <strong>Discount:</strong> {discount}%
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {image && (
                                                            <div>
                                                                <img
                                                                    src={image}
                                                                    alt={serviceName}
                                                                    width="100"
                                                                    height="100"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </li>
                                            )}
                                        </div>
                                    )
                                )}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default AcUpdatePanel;
