import React, { useState } from 'react';
import styles from './SearchBar.module.css';
import SearchIcon from '../../assets/icons/search.svg';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import IndiaFlag from '../../assets/icons/IndiaFlag.png'

const servicesData = [
  { service: "Split AC Service", link: '/ac/service' },
  { service: "Split AC Gas Filling", link: '/ac/service' },
  { service: "Split AC Repairing", link: '/ac/service' },
  { service: "Split AC Coating", link: '/ac/service' },
  { service: "Split AC Combo Offer", link: '/ac/service' },
  { service: "Window AC Service", link: '/ac/service' },
  { service: "Window AC Gas Filling", link: '/ac/service' },
  { service: "Window AC Repairing", link: '/ac/service' },
  { service: "Window AC Coating", link: '/ac/service' },
  { service: "Window Combo Offer", link: '/ac/service' },
  { service: "Refrigerator Service", link: '/refrigerator/services' },
  { service: "Refrigerator Gas Filling", link: '/refrigerator/services' },
  { service: "Refrigerator Repairing", link: '/refrigerator/services' },
  { service: "Refrigerator Coating", link: '/refrigerator/services' },
  { service: "Refrigerator Motor Change", link: '/refrigerator/services' },
  { service: "Refrigerator Filter Change", link: '/refrigerator/services' },
  { service: "Refrigerator Combo Offer", link: '/refrigerator/offer' },
  { service: "Semi-Automatic Washing Machine Service", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Gearbox Change(6 Kg)", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Gearbox Change(7 Kg)", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Gearbox Change(8 Kg)", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Washing Motor Change", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Spin Motor Change", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Spin Timer Change", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Wash Timer Change", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Belt Change", link: '/washing-machine/all-services' },
  { service: "Semi-Automatic Washing Machine Pal Seater (6/7 Kg)", link: '/washing-machine/all-services' },
  { service: "Automatic Washing Machine Service", link: '/washing-machine/all-services' },
  { service: "Automatic Washing MachineSensor Change", link: '/washing-machine/all-services' },
  { service: "Automatic Washing MachinePCB Repairing", link: '/washing-machine/all-services' },
  { service: "Automatic Washing MachineMotor Change", link: '/washing-machine/all-services' },
  { service: "Automatic Washing MachineBelt Change", link: '/washing-machine/all-services' },
  { service: "Microwave Service", link: '/microwave/all-services' },
  { service: "Microwave Repair", link: '/microwave/all-services' },
  { service: "Microwave Magnetron Change", link: '/microwave/all-services' },
  { service: "Geyser Heater Element Change", link: '/microwave/all-services' },
  { service: "Digital RO Water Purifier Service", link: '/ro/all-services' },
  { service: "Normal RO Water Purifier Service", link: '/ro/all-services' },
  { service: "Mineral Change", link: '/ro/all-services' },
  { service: "Motor Change", link: '/ro/all-services' },
  { service: "Candle Change", link: '/ro/all-services' },
  { service: "Digital RO 1 Year AMC + 4 Services(Free)", link: '/ro/all-services' },
  { service: "Ceilin Fan Installation", link: '/other-services/all-services' },
  { service: "Ceiling Fan Uninstallation", link: '/other-services/all-services' },
  { service: "Ceiling Fan Capacitor Change", link: '/other-services/all-services' },
  { service: "Air Cooler Fan Capacitor Change", link: '/other-services/all-services' },
  { service: "Air Cooler Fan Motor Change", link: '/other-services/all-services' },
  { service: "Air Cooler Water Pump Change", link: '/other-services/all-services' },
  { service: "All type of Switch Socket Change", link: '/other-services/all-services' },
  { service: "AC Rent", link: '/ac-on-rent' },
  { service: "Window AC Rent", link: '/ac-on-rent' },
  { service: "Split AC Rent", link: '/ac-on-rent' },
  { service: "Water Cooler Rent", link: '/water-cooler-on-rent' },
  { service: "Refrigerator Rent", link: '/refrigerator-on-rent' },
  { service: "Washing Machine Rent", link: '/washing-machine-on-rent' },
  { service: "Microwave Rent", link: '/microwave-on-rent' },
  { service: "Ceiling Fan Rent", link: '/ceiling-fan-on-rent' },
]

const CityDropDown = () => {

  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            /* Your styles here */
            border: "none !important"
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
    <div className={styles.flex}>
      <div className={styles.flag}>
          <img src={IndiaFlag} alt="India Flag" />
      </div>
      <FormControl fullWidth sx={{ border: "none !important" }}>
          {/* <span className={styles.delhi}>New Delhi</span> */}
        <Select
          value={""}
          onChange={""}
          displayEmpty
          sx={{ border: "none  !important" }}
        >
          <MenuItem value="" sx={{ border: "none !important", marginTop:".2rem !important" }}>
            <em>New Delhi</em>
          </MenuItem>

          {/* <MenuItem value={10}>Gurugram</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>Without label</FormHelperText> */}
      </FormControl>
    </div>
    </ThemeProvider>
  )

}


const SearchInput = ({ onSearch }) => {
  const [searchText, setSearchText] = useState('');

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className={styles.searchCont}>
      <span>
        <img src={SearchIcon} alt="Search Icon" />
      </span>
      <span>
        <input
          type="text"
          placeholder="What do you want?"
          value={searchText}
          onChange={handleInputChange}
        />
      </span>
    </div>
  );
};

const SearchResult = ({ service }) => (
  <a href={service.link} className={styles.searchResult}>
    {service.service}
  </a>
);

const SearchBar = () => {
  const [filteredServices, setFilteredServices] = useState([]);

  const handleSearch = (searchText) => {
    const filtered = servicesData.filter((service) =>
      service.service.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  return (
    <div className={styles.searchHeading}>
      <h1>Customer services, on demand.</h1>
      <div className={styles.flex}>
        <div className={styles.city}><CityDropDown /></div>
        <div>
          <SearchInput onSearch={handleSearch} />
          {
            filteredServices.length < servicesData.length && (
              <div className={styles.resultsContainer} style={{ display: ` ${(props) => (props.isVisible ? 'block' : 'none')}` }}>
                {filteredServices.map((service, index) => (
                  <SearchResult key={index} service={service} />
                ))}
              </div>
            )
          }
        </div>
      </div>

    </div>
  );
};

export default SearchBar;
