import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./AcModal.module.css";
import Typography from "@mui/material/Typography";
import { UniversalContext } from "../../../context/UniversalContext/UniversalContext";
import { Button, IconButton } from "@mui/material";
import { CancelPresentation } from "@mui/icons-material";
import { Link, Navigate, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem ",
  boxShadow: "1px 1px 1rem grey",
  p: 4,
};

export default function AcModal() {
  const { acModal, setAcModal } = React.useContext(UniversalContext);
  const [open, setOpen] = React.useState(acModal);
  const handleClose = () => setAcModal(false);
  const navigate = useNavigate()


  const buttonClickHandler = (type) => {
    navigate(`/ac/${type}`)
    setAcModal(false)
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className={styles.flexRow}>
            <h2>Choose your need</h2>
            <IconButton
              color="error"
              aria-label="add to shopping cart"
              onClick={handleClose}
            >
              <CancelPresentation />
            </IconButton>
          </div>
          <Typography
            id="transition-modal-description"
            sx={{ mt: 2 }}
            className={styles.flexCol}
          >
            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("install")}>
              AC Installation
            </Button>
            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("uninstall")}>
              AC Uninstallation
            </Button>
            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("service")}>
              AC Services Details
            </Button>
            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("gas-filling")}>
              AC Gas Filling Details
            </Button>
            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("repairing")}>
              Repairing Charges Details
            </Button>
            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("coating")}>
              Coating Charges Details
            </Button>

            <Button variant="contained" className={styles.modalButton2} onClick={() => buttonClickHandler("offer")}>
              Gas Charging Combo Offer
            </Button>
          </Typography>
          {/* <div className={styles.flexRow}>
                        <Button variant='contained' onClick={handleClose} className={styles.modalButton}>Book Now</Button>
                        <Button variant='contained' onClick={handleClose} className={styles.modalButton}>Close</Button>
                    </div> */}
        </Box>
      </Fade>
    </Modal>
  );
}
