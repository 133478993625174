import React from "react";
import styles from "./Footer.module.css";
import logo from "../../assets/logo/withText-white.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link, useNavigate } from "react-router-dom";
import { UniversalContext } from "../../context/UniversalContext/UniversalContext";
import AppointmentModal from "../Modal/AppointmentModal/AppointmentModal";
import ProfessionalRegistrationModal from "../Modal/ProfessionalRegistrationModal/ProfessionalRegistrationModal";

const Footer = () => {

    const {
        appointmentModal, setAppointmentModal,
        professionalRegistrationModal, setProfessionalRegistrationModal
    } = React.useContext(UniversalContext)
const navigate = useNavigate();

    return (
        <div className={styles.FooterCont}>
            <div>
                <ul>
                    <Link to='/'><li>Home</li></Link>
                    <li onClick={() => navigate('/about')}>About Us</li>
                    <li onClick={() => navigate('/contact')}>Contact</li>
                    <li onClick={() => setProfessionalRegistrationModal(true)}>Register as an Professional</li>
                </ul>
            </div>
            <div className={styles.hr} />

            <div className={styles.logoCont}>
                <div className={styles.cp}>
                    <img src={logo} alt="Direct Repair Services" />
                </div>
                <div className={styles.center}>
                    <p>© 2023 Direct Repair Services.</p>
                </div>
                <div className={styles.centerFlex}>
                    <InstagramIcon size="small" />
                    <FacebookIcon size="small" />
                    <YouTubeIcon size="small" />
                    <TwitterIcon size="small" />
                </div>
                {/* <div></div> */}
            </div>
            <div className={styles.responsive}>
                <div className={styles.hr} />
                <p>© 2023 Direct Repair Services.</p>
            </div>
            {appointmentModal && <AppointmentModal />}
            {professionalRegistrationModal && <ProfessionalRegistrationModal />}
        </div>
    );
};

export default Footer;
