import React from 'react'
import styles from './Navbar.module.css'
import logo from '../../assets/logo/Direct_Repair_Services-removebg-preview.png'
import { UniversalContext } from '../../context/UniversalContext/UniversalContext'
import AppointmentModal from '../Modal/AppointmentModal/AppointmentModal'
import Hamburger from '../Hamburger/Hamburger'
import { Link, useNavigate } from 'react-router-dom'
import ProfessionalRegistrationModal from '../Modal/ProfessionalRegistrationModal/ProfessionalRegistrationModal'


const Navbar = () => {
  const {
    appointmentModal, setAppointmentModal,
    professionalRegistrationModal, setProfessionalRegistrationModal
  } = React.useContext(UniversalContext)
  const navigate = useNavigate();

  return (
    <div className={styles.navCont}>
      <div>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div>
        <ul className={styles.Ham}>
          <Hamburger />
        </ul>
        <ul className={styles.ulist}>
          <li onClick={() => setProfessionalRegistrationModal(true)}>Register As A Professional</li>
          <li onClick={() => navigate('/about')}>About</li>
          <li onClick={() => navigate('/contact')}>Contact Us</li>
        </ul>
      </div>
      {appointmentModal && <AppointmentModal />}
      {professionalRegistrationModal && <ProfessionalRegistrationModal />}
    </div>
  )
}

export default Navbar