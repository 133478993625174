import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase';
import styles from './Dashboard.module.css';

const Dashboard = () => {
    const { setEmail } =
        useContext(AuthContext);

    const navigate = useNavigate();
    const logoutHandler = async () => {
        // eslint-disable-next-line no-unused-vars
        const res = await signOut(auth);
        navigate('/admin');
        setEmail('');
    }

    const acButtonClickHandler = (link) => {
        navigate(`/admin/ac/${link}`)
    }

    const refButtonClickHandler = (link) => { 
        navigate(`/admin/refrigerator/${link}`)
    }
    const wmButtonClickHandler = (link) => {
        navigate(`/admin/washing-machine/${link}`)
     }
    const microwaveButtonClickHandler = (link) => { 
        navigate(`/admin/microwave/${link}`)
    }
    const geyserButtonClickHandler = (link) => {
        navigate(`/admin/geyser/${link}`)
     }
    const roButtonClickHandler = (link) => {
        navigate(`/admin/ro/${link}`)
     }
    const electricalButtonClickHandler = (link) => { 
        navigate(`/admin/electrical/${link}`)
    }
    


    return (
        <>
            <div className={styles.navbar}>
                <h1>Admin Dashboard</h1>
                <div className={styles.buttonCont}>
                    <Button variant='outlined' color='secondary'><Link to='/admin/link' target='_blank'>Generate link</Link></Button>
                    <Button variant='outlined' color='error' onClick={logoutHandler}>Logout</Button>
                </div>
            </div>
            <h2 className={styles.heading2}>Air Conditioner</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => acButtonClickHandler('AcService')}>Service Details</Button>
                <Button variant="contained" onClick={() => acButtonClickHandler('AcGasFilling')}>Gas Filling Services</Button>
                <Button variant="contained" onClick={() => acButtonClickHandler('AcRepairing')}>Repairing Services</Button>
                <Button variant="contained" onClick={() => acButtonClickHandler('AcCoating')}>Coating Services</Button>
                <Button variant="contained" onClick={() => acButtonClickHandler('AcOffer')}>Combo Offers</Button>
                <Button variant="contained" onClick={() => acButtonClickHandler('AcOnRent')}>AC Rent</Button>
            </div>

            <h2 className={styles.heading2}>Refrigerator</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => refButtonClickHandler('RefGasFilling')}>View All Services</Button>
                <Button variant="contained" onClick={() => refButtonClickHandler('RefOffer')}>Combo Offers</Button>
                <Button variant="contained" onClick={() => refButtonClickHandler('RefOnRent')}>Refrigerator Rent</Button>
            </div>

            <h2 className={styles.heading2}>Washing Machine</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => wmButtonClickHandler('WmServices')}>View All Services</Button>
                <Button variant="contained" onClick={() => wmButtonClickHandler('WmOnRent')}>Washing Machine Rent</Button>
            </div>

            <h2 className={styles.heading2}>Microwave</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => microwaveButtonClickHandler('MicrowaveServices')}>View All Services</Button>
                <Button variant="contained" onClick={() => microwaveButtonClickHandler('MicrowaveOnRent')}>Microwave Rent</Button>
            </div>

            <h2 className={styles.heading2}>Water Geyser</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => geyserButtonClickHandler('GeyserServices')}>View All Services</Button>
            </div>
            <h2 className={styles.heading2}>Ro / Water Purifier</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => roButtonClickHandler('RoServices')}>View All Services</Button>
            </div>
            <h2 className={styles.heading2}>Electrical Services</h2>
            <div className={styles.buttonsGroup}>
                <Button variant="contained" onClick={() => electricalButtonClickHandler('OtherServices')}>View All Services</Button>
                <Button variant="contained" onClick={() => electricalButtonClickHandler('FanOnRent')}>Ceiling Fan Rent</Button>
                <Button variant="contained" onClick={() => electricalButtonClickHandler('WcOnRent')}>Water Cooler Rent</Button>
            </div>
        </>
    )
}

export default Dashboard