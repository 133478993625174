import React, { useState } from 'react';
import styles from './ActionButton.module.css'; // Create a CSS file for styling
import { Close, DownloadOutlined, MessageOutlined, WhatsApp } from '@mui/icons-material';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { UniversalContext } from '../../context/UniversalContext/UniversalContext';
import AppointmentModal from '../Modal/AppointmentModal/AppointmentModal';
import APK from '../../assets/Direct Repair Services_1_1.0.apk'

const ActionButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {appointmentModal, setAppointmentModal} = React.useContext(UniversalContext)

    const toggleOptions = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.floatingButtonContainer}>
            <button className={styles.floatingButton} onClick={toggleOptions}>
                {isOpen ? <Close /> : <MessageOutlined />}
            </button>
            {isOpen && (
                <div className={styles.floatingOptions}>
                    <a href={APK} className={styles.download}>
                        <span className={styles.icons}><DownloadOutlined /></span>
                        Download App
                    </a>
                    <div className={styles.appointment} onClick={() => setAppointmentModal(true)}>
                        <span className={styles.icons}><EditLocationAltIcon /></span>
                        Appointment
                    </div>
                    <div className={styles.wa}>
                        <span className={styles.icons}><WhatsApp /></span>
                        <a href="https://api.whatsapp.com/send/?phone=919654254713&text=Customer+Services+on+demand%21&type=phone_number&app_absent=0" >
                            WhatsApp
                        </a>
                    </div>
                </div>
            )}
            {appointmentModal && <AppointmentModal />}
        </div>
    );
};

export default ActionButton;
