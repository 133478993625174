import React, { useContext } from 'react'
import styles from './SingleItemBox.module.css'
import { UniversalContext } from '../../context/UniversalContext/UniversalContext'
import AcModal from '../Modal/AcModal/AcModal'
import RefrigeratorModal from '../Modal/RefrigeratorModal/RefrigeratorModal'
import WashingMachineModal from '../Modal/WashingMachineModal/WashingMachineModal'
import MicrowaveModal from '../Modal/MicrowaveModal/MicrowaveModal'
import GeyserModal from '../Modal/GeyserModal/GeyserModal'
import RoModal from '../Modal/RoModal/RoModal'
import OtherServicesModal from '../Modal/OtherServicesModal/OtherServicesModal'

const SingleItemBox = (props) => {

  const { acModal, setAcModal,
    refrigeratorModal, setRefrigeratorModal,
    washingMachineModal, setWashingMachineModal,
    microwaveModal, setMicrowaveModal,
    geyserModal, setGeyserModal,
    roModal, setRoModal,
    otherServicesModal, setOtherServicesModal,
  } = useContext(UniversalContext)

  const clickHandler = () => {
    if (props.text === 'Air Conditioner') {
      setAcModal(true)
    }
    if (props.text === 'Refrigerator') {
      setRefrigeratorModal(true)
    }
    if (props.text === 'Washing Machine') {
      setWashingMachineModal(true)
    }
    if (props.text === 'Microwave') {
      setMicrowaveModal(true)
    }
    if (props.text === 'Water Geyser') {
      setGeyserModal(true)
    }
    if (props.text === 'RO/Water Purifier') {
      setRoModal(true)
    }
    if (props.text === 'Other Services') {
      setOtherServicesModal(true)
    }
  }

  return (
    <>
      <li className={styles.list} onClick={clickHandler}>
        <img src={props.image} alt='AC' />
        <p>{props.text}</p>
      </li>
      {acModal && <AcModal />}
      {refrigeratorModal && <RefrigeratorModal />}
      {washingMachineModal && <WashingMachineModal />}
      {microwaveModal && <MicrowaveModal />}
      {geyserModal && <GeyserModal />}
      {roModal && <RoModal />}
      {otherServicesModal && <OtherServicesModal />}
    </>
  )
}

export default SingleItemBox