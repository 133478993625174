import React from "react";
import styles from "./Renting.module.css";

// Import illustrations
import ACIcon from "../../assets/images/acImg.png";
import RefrigeratorIcon from "../../assets/images/fridgeImg.png";
import WashingMachineIcon from "../../assets/images/wmImg.png";
import MicrowaveIcon from "../../assets/images/microwaveImg.png";
import CeilingFanIcon from "../../assets/images/fanImg.png";
import WaterCoolerIcon from "../../assets/images/wcImg.png";

const Renting = () => {
    const services = [
        {
            id: 1,
            name: "AC",
            icon: ACIcon,
            link: "/ac-on-rent",
        },
        {
            id: 2,
            name: "Refrigerator",
            icon: RefrigeratorIcon,
            link: "/refrigerator-on-rent",
        },
        {
            id: 3,
            name: "Washing Machine",
            icon: WashingMachineIcon,
            link: "/washing-machine-on-rent",
        },
        {
            id: 4,
            name: "Microwave",
            icon: MicrowaveIcon,
            link: "/microwave-on-rent",
        },
        {
            id: 5,
            name: "Ceiling Fan",
            icon: CeilingFanIcon,
            link: "/ceiling-fan-on-rent",
        },
        {
            id: 6,
            name: "Water Cooler",
            icon: WaterCoolerIcon,
            link: "/water-cooler-on-rent",
        },
    ];

    return (<>
        <h1 className={styles.heading}>Renting Services</h1>
        <div className={styles.rentingServices}>
            {services.map((service) => (
                <div className={styles.rentingServiceCard} key={service.id}>
                    <div className={styles.flexCol}>
                        <div className={styles.rentingServiceIcon}>
                            <img src={service.icon} alt={service.name} />
                        </div>
                        <div className={styles.rentingServiceName}>
                            <h3>{service.name}</h3>
                        </div>
                    </div>
                    <div className={styles.rentingServiceButton}>
                        <a href={service.link}>Rent Now</a>
                    </div>
                </div>
            ))}
        </div>
    </>
    );
};

export default Renting;
