import React, { useEffect } from "react";
import styles from "./HomePage.module.css";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from "../../components/SearchBar/SearchBar";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import FirstBox from "../../components/FirstBox/FirstBox";
import Footer from "../../components/Footer/Footer";
import { UniversalContext } from "../../context/UniversalContext/UniversalContext";
import Renting from "../../components/Renting/Renting";
import Download from "../../components/Download/Download";
import PopupModal from "../../components/Modal/PopupModal/PopupModal";
import Cart from "../../components/Cart/Cart";

const MODAL_SHOW_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
// const MODAL_SHOW_INTERVAL = 100; // 2 hours in milliseconds

const HomePage = () => {
  const { popupModal, setPopupModal } =
    React.useContext(UniversalContext);

  useEffect(() => {
    // Check if the modal was shown in the last 2 hours
    const lastModalShowTime = localStorage.getItem("lastModalShowTime");
    const currentTime = Date.now();

    if (!lastModalShowTime || currentTime - lastModalShowTime >= MODAL_SHOW_INTERVAL) {
      // Show the modal
      setPopupModal(true);

      // Update the last modal show time
      localStorage.setItem("lastModalShowTime", currentTime);
    }
  }, [setPopupModal]);


  return (
    <>
      <div className={styles.App}>
        <Navbar />
        <SearchBar />
        <ImageSlider />
        <FirstBox />
        <Renting />
        <p className={styles.infoText1}>Some new services adding very soon.....</p>
        <Download />
        <div className={styles.infoCard}>
          <h2 className={styles.infoTitle}>Visit Charge 199/- only</h2>
          <p className={styles.infoText}>
            Book appointments and get on-door visit within 1 to 4 hours during working hours (9am to 8pm) on working days (Tuesday to Sunday).
          </p>
          <p className={styles.infoText}>Recomplaint visit on-door within 24 hours on working days.</p>
        </div>
        <div className={styles.amc}>
          AMC Available for AC, Refrigerator, Washing Machine, Microwave and Ceiling Fan.
          <br />
          <span className={styles.tnc}>Terms & Conditions Apply.</span>
        </div>
        <Cart />
        <Cart /><Footer />
      </div>
      {/* <FloatingButton />
      <FloatingButtonWa /> */}

      {/* <ReactWhatsapp
        className={styles.wa}
        number="+91 9654254713"
        message="Customer Services on demand!"
      >
        <img src={Wa} alt="Whatsapp Icon" />
      </ReactWhatsapp> */}

      {popupModal && <PopupModal />}
    </>
  );
};

export default HomePage;
