/* eslint-disable react-hooks/exhaustive-deps */
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import styles from "./RefrigeratorServices.module.css";
import Illustration from "../../assets/images/RefIllustration.png";
import { Button, Tooltip } from "@mui/material";
import { UniversalContext } from "../../context/UniversalContext/UniversalContext";
import AppointmentModal from "../../components/Modal/AppointmentModal/AppointmentModal";

import { v4 as uuidv4 } from 'uuid';

// const AcSrviceDetails = [

//     {
//         name: "Split AC", services: [
//             { name: "Power Jet Service", price: 500, discount: 30 },
//             { name: "Foam Power Jet Service", price: 700, discount: 35 },
//             { name: "Chemical Power Jet Service", price: 799, discount: 25 },
//         ]
//     },
//     {
//         name: "Window AC", services: [
//             { name: "Power Jet Service", price: 400, discount: 30 },
//             { name: "Foam Power Jet Service", price: 500, discount: 30 },
//             { name: "Chemical Power Jet Service", price: 699, discount: 30 },
//         ]
//     },
//     {
//         name: "Cassette AC", services: [
//             { name: "Power Jet Service", price: 1499, discount: 30 },
//             { name: "Foam Power Jet Service", price: 1749, discount: 24 },
//             { name: "Chemical Power Jet Service", price: 1999, discount: 30 },
//         ]
//     }
// ]

const RefrigeratorServices = () => {
  const [title, setTitle] = useState("");
  const { type } = useParams();
  const [data, setData] = useState([]);
  const { appointmentModal, setAppointmentModal, cart, setCart } =
    React.useContext(UniversalContext);

  function arrangeInDecreasingOrderByName(data) {
    return data.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA > nameB) {
        return -1; // Sort a before b
      }
      if (nameA < nameB) {
        return 1; // Sort b before a
      }
      return 0; // Names are equal
    });
  }

  function transformArray(inputArray) {
    const transformedData = {};

    inputArray.forEach((item) => {
      const { id, ...services } = item;

      const transformedItem = {
        name:
          id === "single"
            ? "Single Door"
            : id === "double"
              ? "Double Door"
              : "Deep Freezer",
        services: [],
      };
      for (const serviceName in services) {
        if (services.hasOwnProperty(serviceName)) {
          const [price, discount, link] = services[serviceName];
          transformedItem.services.push({
            name: serviceName,
            price,
            discount,
            link,
          });
        }
      }
      transformedData[transformedItem.name] = transformedItem;
    });
    const transformedArray = Object.values(transformedData);
    arrangeInDecreasingOrderByName(transformedArray);
    return transformedArray;
  }

  useEffect(() => {
    console.log(type);

    if (type === "services") {
      setTitle("Refrigerator Services");
      const fetch = async () => {
        try {
          const docRef = await getDocs(collection(db, "RefGasFilling"));
          const docs = docRef.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          console.log(transformArray(docs));
          setData(transformArray(docs));
        } catch (error) {
          console.log(error);
        }
      };
      fetch();
    }

    if (type === "offer") {
      setTitle("Combo Offers");
      const fetch = async () => {
        try {
          const docRef = await getDocs(collection(db, "RefOffer"));
          const docs = docRef.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          console.log(transformArray(docs));
          setData(transformArray(docs));
        } catch (error) {
          console.log(error);
        }
      };
      fetch();
    }
  }, []);
  return (
    <>
      <div className={styles.parentCont}>
        <h1 className={styles.Heading}>
          <span>{title}</span>
          <Button
            variant="contained"
            onClick={() => {
              setAppointmentModal(true);
            }}
          >
            Book Now
          </Button>
        </h1>
        <i style={{ marginLeft: "4rem", color: "gray", fontSize: ".8rem", fontWeight: "bold" }}>Click on the items to add them to cart</i>
        {type === "gas-filling" && (
          <div className={styles.warranty}>
            All type of Refrigerator Gas Charging - 120 days warranty
          </div>
        )}
        <div className={styles.flexRow}>
          {/* <div>
            <img src={Illustration} alt="Repair Illustration" />
          </div> */}
          <div className={styles.Container}>
            {data.map((item) => (
              <div key={item.name}>
                <h1 className={styles.Title}>{item.name}</h1>
                <div className={styles.Card}>
                  {item.services.map((service) => (
                    <Tooltip title="Click to Add to Cart" placement="top">
                    <div onClick={() => {
                      const appliance = item.name;
                      const id = uuidv4(); const ns = { ...service, appliance, id }
                      setCart([...cart, ns])
                    }
                    } className={styles.cardDiv} style={{ cursor: "pointer" }}>
                      <div className={styles.imageCont}>
                        <img className={styles.image} src={service.link} alt={service.name} />
                      </div>
                      <div key={service.name} className={styles.Service}>
                        <h2 className={styles.ServiceName}>{service.name}</h2>
                        <div className={styles.ServiceDetails}>
                          <div className={styles.DiscountPrice}>
                            <span className={styles.InitialPrice}>
                              ₹{service.price}
                            </span>
                            <span className={styles.FinalPrice}>
                              ₹
                              {service.price -
                                (service.price * service.discount) / 100}
                            </span>
                          </div>
                          <div className={styles.Discount}>
                            Discount: {service.discount}%
                          </div>
                        </div>
                      </div>
                    </div>
                    </Tooltip>
                  ))}
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
      {appointmentModal && <AppointmentModal />}
    </>
  );
};

export default RefrigeratorServices;
