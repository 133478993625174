/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { getDownloadURL, listAll, ref, uploadBytes, deleteObject } from "firebase/storage";
import { auth, storage } from '../../../firebase';
import styles from '../Dashboard/Dashboard.module.css'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { AuthContext } from '../../../context/AuthContext/AuthContext';

const GenerateLink = () => {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [imageList, setImageList] = useState([]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const extractLastWord = (inputString) => {
        const parts = inputString.split("/");
        const lastPart = parts[parts.length - 1];
        const lastWord = lastPart.split("_").join(" ");
        return lastWord;
    };

    const filesRef = ref(storage, `images/`);

    const solve = async () => {
        const res = await listAll(filesRef);
        const itemsRef = await res.items;

        const images = await Promise.all(itemsRef.map(async (item) => {
            const name = extractLastWord(item._location.path_);
            const url = await getDownloadURL(item);
            return { name, url };
        }));

        setImageList(images);
    };

    useEffect(() => {
        solve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpload = () => {
        if (image == null) {
            alert("Please Choose Photograph to continue!");
            return;
        }
        const fileRef = ref(storage, `images/${image.name}`);
        uploadBytes(fileRef, image).then(() => {
            alert("Photograph Uploaded");
            solve();
        });
        setImage(null);
    };

    const handleDelete = async (imageName) => {
        const imageRef = ref(storage, `images/${imageName}`);
        try {
            await deleteObject(imageRef);
            const updatedImages = imageList.filter((imageItem) => imageItem.name !== imageName);
            setImageList(updatedImages);
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };

    const navigate = useNavigate();
    const { setEmail } = useContext(AuthContext);

    const logoutHandler = async () => {
        const res = await signOut(auth);
        navigate('/admin');
        setEmail('');
    };

    return (
        <div>
            <div className={styles.navbar}>
                <h1>Admin Dashboard</h1>
                <div className={styles.buttonCont}>
                    <Button variant='outlined' color='primary' onClick={() => navigate('/admin/dashboard')}>Back</Button>
                    <Button variant='outlined' color='error' onClick={logoutHandler}>Logout</Button>
                </div>
            </div>
            <h2 className={styles.heading2}>Upload Image</h2>
           <div className={styles.actionCont}>
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <Button color='secondary' variant='contained' onClick={handleUpload}>Upload Image</Button>
           </div>
            {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
            {imageUrl && (
                <div>
                    <p>Image uploaded successfully!</p>
                    <img src={imageUrl} alt="Uploaded"  />
                    <p>Image URL: {imageUrl}</p>
                </div>
            )}
            <h2 className={styles.heading2}>Available Images</h2>
            <ul sx={{listStyle:"none"}}>
                {imageList.map((imageItem) => (
                    <li className={styles.imgCont} key={imageItem.name}>
                        <img className={styles.image} src={imageItem.url} alt={imageItem.name} />
                        <Button sx={{float:"right", margin:"2rem"}} variant='contained' color='error' onClick={() => handleDelete(imageItem.name)}>Delete</Button>
                        <p>{imageItem.url}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GenerateLink;
