import React from 'react'
import styles from './NavbarMessages.module.css'
import logo from '../../assets/logo/withText-white.png'
import { Link } from 'react-router-dom'
import Hamburger from '../Hamburger/Hamburger'


const NavbarMessages = () => {

  return (
    <div className={styles.navCont}>
      <div>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>      </div>
      <div>
        <Hamburger />
      </div>
    </div>
  )
}

export default NavbarMessages