import React from 'react'
import styles from './NavbarContained.module.css'
import logo from '../../assets/logo/withText-white.png'
import { UniversalContext } from '../../context/UniversalContext/UniversalContext'
import AppointmentModal from '../Modal/AppointmentModal/AppointmentModal'
import Hamburger from '../Hamburger/Hamburger'
import { Link, useNavigate } from 'react-router-dom'


const NavbarContained = () => {
  const { appointmentModal, setAppointmentModal } = React.useContext(UniversalContext)
  const navigate = useNavigate();

  return (
    <div className={styles.navCont}>
      <div>
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>      </div>
      <div>
        <ul className={styles.Ham}>
          <Hamburger />
        </ul>
        <ul className={styles.ulist}>
          <li onClick={() => setAppointmentModal(true)}>Register As A Professional</li>
          <li onClick={() => navigate('/')}>Home</li>
          <li onClick={() => navigate('/contact')}>Contact Us</li>
        </ul>
      </div>
      {appointmentModal && <AppointmentModal />}
    </div>
  )
}

export default NavbarContained