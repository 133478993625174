import React from 'react'
import styles from './Download.module.css'
import { DownloadOutlined } from '@mui/icons-material'
import APK from '../../assets/Direct Repair Services_1_1.0.apk'

const Download = () => {
  return (
      <div className={styles.card}>
          <span className={styles.smallText}>Download Now!</span>
          <span className={styles.title}>Download our mobile application.</span>
          {/* <span className={styles.desc}>Download Lorem mobile banking app for IOS &amp; Android to manage your online money.</span> */}
          <div className={styles.buttons}>
              {/* <a href="/" className={styles.button}>
                  <span className={styles.icon}><svg width="34" height="34" viewBox="0 0 34 34" fill="" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 28.9958V4.9125C4 4.07667 4.48167 3.34 5.19 3L19.1442 16.9542L5.19 30.9083C4.48167 30.5542 4 29.8317 4 28.9958ZM23.5642 21.3742L8.32083 30.1858L20.3483 18.1583L23.5642 21.3742ZM28.31 15.2683C28.7917 15.6508 29.1458 16.2458 29.1458 16.9542C29.1458 17.6625 28.8342 18.2292 28.3383 18.6258L25.0942 20.4958L21.5525 16.9542L25.0942 13.4125L28.31 15.2683ZM8.32083 3.7225L23.5642 12.5342L20.3483 15.75L8.32083 3.7225Z" fill="white"></path>
                  </svg></span>
                  <div className={`${styles.buttonText} ${styles.google}`}>
                      <span>Get it on</span>
                      <span>Google Play</span>
                  </div>
              </a> */}
              <a href={APK} className={styles.button}>
                  <span className={styles.icon}><DownloadOutlined /></span>
                  <div className={`${styles.buttonText} ${styles.apple}` }>
                      <span>&nbsp;&nbsp;Download</span>
                  </div>
              </a>
          </div>
      </div>
  )
}

export default Download