import React from 'react'
import one from '../../assets/images/background-front.webp'
import styles from './ImageSlider.module.css'

const ImageSlider = () => {
  return (
      <div className={styles.imageContainer}>
          <div className={styles.imageOverlay}></div>
          <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel" data-interval="5000">
              <div className="carousel-inner">
                  <div className="carousel-item active">
                      <img className="d-block w-100" src={one} alt="First slide" />
                  </div>
                  <div className="carousel-item">
                      <img className="d-block w-100" src={one} alt="Third slide" />
                  </div>
              </div>
          </div>
      </div>
  )
}

export default ImageSlider