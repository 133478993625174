import React from 'react'
import ItemsContainer from '../ItemsContainer/ItemsContainer'
import SingleItemBox from '../SingleItemBox/SingleItemBox'
import AcImage from '../../assets/images/ac.png'
import FridgeImage from '../../assets/images/fridge.png'
import WashingMachineImage from '../../assets/images/washing-machine.png'
import MicrowaveImage from '../../assets/images/Microwave.png'
import GeyserImage from '../../assets/images/geyser.png'
import PurifierImage from '../../assets/images/purifier.png'
import OtherImage from '../../assets/images/other.png'
import styles from './FirstBox.module.css'

const FirstBox = () => {
  return (
    <div className={styles.firstBox}>
      <h1>Our Services</h1> 
      <ItemsContainer>
          <SingleItemBox image={AcImage} text='Air Conditioner' />
          <SingleItemBox image={FridgeImage} text='Refrigerator' />
          <SingleItemBox image={WashingMachineImage} text='Washing Machine' />
          <SingleItemBox image={MicrowaveImage} text='Microwave' />
          <SingleItemBox image={GeyserImage} text='Water Geyser' />
          <SingleItemBox image={PurifierImage} text='RO/Water Purifier' />
          <SingleItemBox image={OtherImage} text='Other Services' />
      </ItemsContainer>
    </div>
  )
}

export default FirstBox