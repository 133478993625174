import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./BookModal.module.css";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from 'uuid';
import { UniversalContext } from "../../../context/UniversalContext/UniversalContext";
import {
    Alert,
    Button,
    IconButton,
    Snackbar,
    TextField,
} from "@mui/material";
import { CancelPresentation, Delete } from "@mui/icons-material";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "1rem ",
    boxShadow: "1px 1px 1rem grey",
    p: 4,
    height: "60%",
    width: "60%"
};

export default function BookModal() {
    const { bookModal, setBookModal, setAlert, cart, setCart } =
        React.useContext(UniversalContext);
    const open = bookModal;
    const [name, setName] = React.useState("");
    const [mobileNumber, setMobileNumber] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [buttonVariant, setButtonVariant] = React.useState("disabled");
    const [openToast, setOpenToast] = React.useState(false);
    const handleClose = () => setBookModal(false);
    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };

    React.useEffect(() => {
        if (name && mobileNumber && address) {
            setButtonVariant("contained");
        } else {
            setButtonVariant("disabled");
        }
    }, [
        name,
        mobileNumber,
        address
    ]);

    const formSubmitHandler = (e) => {
        e.preventDefault();

        // Get the data from the console.log
        const data = {
            id: uuidv4(),
            name,
            mobileNumber,
            address,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            solved: false,
            cart: cart
        };

        // Get the Firestore database

        // Create a document in the `messages` collection with the data
        setDoc(doc(db, "messages", data.id), data).then(() => {
            console.log("Document written");
            setOpenToast(true);
        });

        // Close the modal and show an alert
        handleClose();
        setAlert(true);

        // Hide the alert after 6 seconds
        setTimeout(() => {
            setAlert(false);
        }, 6000);

        setCart([])
    };
    const deleteItem = (id) => {
        setCart(cart.filter(item => item.id !== id))
    }
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={styles.box}>
                        <div className={styles.flexRow}>
                            <h2 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Enter details to Book Appointment</h2>
                            <IconButton
                                color="error"
                                aria-label="add to shopping cart"
                                onClick={handleClose}
                            >
                                <CancelPresentation />
                            </IconButton>
                        </div>
                        <Typography
                            id="transition-modal-description"
                            sx={{ mt: 2 }}
                            className={styles.flexCol}
                        >
                            <form className={styles.form} onSubmit={formSubmitHandler}>
                                <TextField
                                    className={styles.normalInput}
                                    required
                                    id="outlined-required"
                                    label="Name"
                                    fullWidth
                                    defaultValue=""
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    className={styles.normalInput}
                                    type="tel"
                                    required
                                    id="outlined-required"
                                    label="Mobile Number"
                                    fullWidth
                                    defaultValue=""
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                                <TextField
                                    className={styles.normalInput}
                                    required
                                    id="outlined-required"
                                    label="Address"
                                    fullWidth
                                    defaultValue=""
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <div className={styles.cartCont}>
                                    <b>Review your cart:</b>
                                    <br />
                                    {cart.map(item => (
                                        <div key={item.name} >
                                            <div className={styles.card}>
                                                <div style={{ display: "flex", gap: "1rem" }}>
                                                    <div className={styles.image}>
                                                        <img src={item.link} alt="item" />
                                                    </div>
                                                    <div>
                                                        <div>Name: {item.appliance}</div>
                                                        <div>Service: {item.name}</div>
                                                        <div>Price: {item.price}</div>
                                                        <div>Discount: {item.discount}%</div>
                                                    </div>
                                                </div>
                                                <div style={{ cursor: "pointer" }} onClick={() => deleteItem(item.id)}>
                                                    <Delete />
                                                </div>
                                            </div>
                                        </div>
                  ))}
                            </div>
                            <br />
                            <div className={styles.flexRowCenter}>
                                <Button
                                    variant={buttonVariant}
                                    type="submit"
                                    size="large"
                                    className={styles.modalButton}
                                >
                                    Continue
                                </Button>
                            </div>
                        </form>
                    </Typography>
                </Box>
            </Fade>
        </Modal >
            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleToastClose}>
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                    Details Sent Successfully!
                </Alert>
            </Snackbar>
        </>
    );
}
