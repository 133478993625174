import React, { useState } from 'react';
import './Hamburger.css';
import { UniversalContext } from '../../context/UniversalContext/UniversalContext';
import AppointmentModal from '../Modal/AppointmentModal/AppointmentModal';
import ProfessionalRegistrationModal from '../Modal/ProfessionalRegistrationModal/ProfessionalRegistrationModal';
import { useNavigate } from 'react-router-dom';

const Hamburger = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {
        appointmentModal, setAppointmentModal,
        professionalRegistrationModal, setProfessionalRegistrationModal
    } = React.useContext(UniversalContext)

    // const toggleMenu = () => {
    //     setIsMenuOpen(!isMenuOpen);
    // };
    const navigate = useNavigate();


    return (
        <div className="hamburger-container">
            <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            {isMenuOpen && (
                <div className="menu">
                    <ul>
                        <li className='menuLi'><a href='/'>Home</a></li>
                        <li onClick={() => navigate('/about')}>About Us</li>
                        <li onClick={() => navigate('/contact')}>Contact</li>
                        <li onClick={() => setProfessionalRegistrationModal(true)}>Professional Registration</li>
                    </ul>
                </div>
            )}
            {appointmentModal && <AppointmentModal />}
            {professionalRegistrationModal && <ProfessionalRegistrationModal />}
        </div>
    );
};

export default Hamburger;
