import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./AppointmentModal.module.css";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from 'uuid';
import { UniversalContext } from "../../../context/UniversalContext/UniversalContext";
import {
    Alert,
    Button,
    IconButton,
    MenuItem,
    Snackbar,
    TextField,
} from "@mui/material";
import { CancelPresentation } from "@mui/icons-material";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    borderRadius: "1rem ",
    boxShadow: "1px 1px 1rem grey",
    p: 4,
};

const categories = [
    {
        name: "Air Conditioner",
        services: [
            "AC Service",
            "AC Gas Filling",
            "AC Repairing",
            "AC Coating",
            "Combo Offer",
        ],
    },
    {
        name: "Refrigerator",
        services: [
            "Refrigerator Service",
            "Refrigerator Gas Filling",
            "Refrigerator Repairing",
            "Refrigerator Coating",
            "Fan Motor Change",
            "Filter Change",
            "Combo Offer",
        ],
    },
    {
        name: "Semi-Automatic Washing Machine",
        services: [
            "Washing Machine Service",
            "Gearbox Change(6 Kg)",
            "Gearbox Change(7 Kg)",
            "Gearbox Change(8 Kg)",
            "Washing Motor Change",
            "Spin Motor Change",
            "Spin Timer Change",
            "Wash Timer Change",
            "Belt Change",
            "Pal Seater (6/7 Kg)",
        ],
    },
    {
        name: "Automatic Washing Machine",
        services: [
            "Washing Machine Service",
            "PCB Repairing",
            "Motor Change",
            "Sensor Change",
            "Belt Change",
        ],
    },
    {
        name: "Microwave",
        services: [
            "Microwave Service",
            "PCB Repair",
            "Magnetron Change"],
    },
    {
        name: "Water Geyser",
        services: [
            "Geyser Heater Element Change",
        ],
    },
    {
        name: "RO/Water Purifier",
        services: [
            "Digital RO Water Purifier Service",
            "Normal RO Water Purifier Service",
            "Mineral Change",
            "Motor Change",
            "Candle Change",
            "Digital RO 1 Year AMC + 4 Services(Free)",
        ],
    },
    {
        name: "Other Services",
        services: [
            "Ceilin Fan Installation",
            "Ceiling Fan Uninstallation",
            "Ceiling Fan Capacitor Change",
            "Air Cooler Fan Capacitor Change",
            "Air Cooler Fan Motor Change",
            "Air Cooler Water Pump Change",
            "All type of Switch Socket Change"
        ],
    },
];

export default function AppointmentModal() {
    const { appointmentModal, setAppointmentModal, setAlert } =
        React.useContext(UniversalContext);
    const open = appointmentModal;
    const [name, setName] = React.useState("");
    const [mobileNumber, setMobileNumber] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [need, setNeed] = React.useState("");
    const [selectedAppliance, setSelectedAppliance] = React.useState("");
    const [selectedService, setSelectedService] = React.useState("");
    const [buttonVariant, setButtonVariant] = React.useState("disabled");
    const [typeOfApplianceToRent, setTypeOfApplianceToRent] = React.useState("");
    const [selectedAcCapacity, setSelectedAcCapacity] = React.useState("");
    const [applianceRentDuration, setApplianceRentDuration] = React.useState("");
    const [openToast, setOpenToast] = React.useState(false);
    const handleClose = () => setAppointmentModal(false);
    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };
    React.useEffect(() => {
        if (
            name !== "" &&
            mobileNumber !== "" &&
            address !== "" &&
            need === "Services" &&
            typeOfApplianceToRent !== "" &&
            selectedAppliance !== "" &&
            selectedService !== "" &&
            selectedAcCapacity !== ""
        ) {
            setButtonVariant("contained");
        } else if (
            name !== "" &&
            mobileNumber !== "" &&
            address !== "" &&
            need === "Services" &&
            (selectedAppliance === "Refrigerator" ||
                selectedAppliance === "Automatic Washing Machine" ||
                selectedAppliance === "Semi-Automatic Washing Machine" ||
                selectedAppliance === "Microwave" ||
                selectedAppliance === "Water Geyser" ||
                selectedAppliance === "RO/Water Purifier" ||
                selectedAppliance === "Other Services"
            ) &&
            selectedService !== ""
        ) {
            setButtonVariant("contained");
        } else if (
            name !== "" &&
            mobileNumber !== "" &&
            address !== "" &&
            need === "Rent" &&
            typeOfApplianceToRent !== "" &&
            applianceRentDuration !== ""
        ) {
            setButtonVariant("contained");
        } else {
            setButtonVariant("disabled");
        }
    }, [
        name,
        mobileNumber,
        address,
        need,
        selectedAppliance,
        typeOfApplianceToRent,
        selectedAcCapacity,
        applianceRentDuration,
        selectedService,
    ]);

    const formSubmitHandler = (e) => {
        e.preventDefault();

        // Get the data from the console.log
        const data = {
            id: uuidv4(),
            name,
            mobileNumber,
            address,
            need,
            selectedAppliance,
            typeOfApplianceToRent,
            selectedService,
            selectedAcCapacity,
            applianceRentDuration,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
            solved: false,
        };
        console.log(data);
        // Get the Firestore database

        // Create a document in the `messages` collection with the data
        setDoc(doc(db, "messages", data.id), data).then(() => {
            console.log("Document written");
            setOpenToast(true);
        });

        // Close the modal and show an alert
        handleClose();
        setAlert(true);

        // Hide the alert after 6 seconds
        setTimeout(() => {
            setAlert(false);
        }, 6000);
    };

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={styles.box}>
                        <div className={styles.flexRow}>
                            <h2 style={{ fontWeight: "bold" }}>Book Appointment</h2>
                            <IconButton
                                color="error"
                                aria-label="add to shopping cart"
                                onClick={handleClose}
                            >
                                <CancelPresentation />
                            </IconButton>
                        </div>
                        <Typography
                            id="transition-modal-description"
                            sx={{ mt: 2 }}
                            className={styles.flexCol}
                        >
                            <form className={styles.form} onSubmit={formSubmitHandler}>
                                <TextField
                                    className={styles.normalInput}
                                    required
                                    id="outlined-required"
                                    label="Name"
                                    fullWidth
                                    defaultValue=""
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    className={styles.normalInput}
                                    type="tel"
                                    required
                                    id="outlined-required"
                                    label="Mobile Number"
                                    fullWidth
                                    defaultValue=""
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                                <TextField
                                    className={styles.normalInput}
                                    required
                                    id="outlined-required"
                                    label="Address"
                                    fullWidth
                                    defaultValue=""
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Select your need"
                                    fullWidth
                                    defaultValue=""
                                    helperText="What do you want to book?"
                                    onChange={(e) => setNeed(e.target.value)}
                                >
                                    <MenuItem key="rent" value="Rent">
                                        Rent
                                    </MenuItem>
                                    <MenuItem key="services" value="Services">
                                        Services
                                    </MenuItem>
                                </TextField>
                                {need === "Rent" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select appliance to rent"
                                        fullWidth
                                        defaultValue=""
                                        onChange={(e) => setTypeOfApplianceToRent(e.target.value)}
                                    >
                                        <MenuItem key="split" value="Split AC">
                                            Split AC
                                        </MenuItem>
                                        <MenuItem key="window" value="Window AC">
                                            Window AC
                                        </MenuItem>
                                        <MenuItem key="refrigerator" value="Refrigerator">
                                            Refrigerator
                                        </MenuItem>
                                        <MenuItem key="wm" value="Washing Machine">
                                            Washing Machine
                                        </MenuItem>
                                        <MenuItem key="microwave" value="Microwave">
                                            Microwave
                                        </MenuItem>
                                        <MenuItem key="fan" value="Ceiling Fan">
                                            Ceiling Fan
                                        </MenuItem>
                                        <MenuItem key="wc" value="Water Cooler">
                                            Water Cooler
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Rent" && typeOfApplianceToRent === "Split AC" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹3500/-"
                                            value="1 Month Rent ₹3500/-"
                                        >
                                            1 Month Rent ₹3500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹6000/-"
                                            value="2 Month Rent ₹6000/-"
                                        >
                                            2 Month Rent ₹6000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹6600/-"
                                            value="3 Month Rent ₹6600/-"
                                        >
                                            3 Month Rent ₹6600/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹8400/-"
                                            value="6 Month Rent ₹8400/-"
                                        >
                                            6 Month Rent ₹8400/-
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Rent" && typeOfApplianceToRent === "Window AC" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹3000/-"
                                            value="1 Month Rent ₹3000/-"
                                        >
                                            1 Month Rent ₹3000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹5000/-"
                                            value="2 Month Rent ₹5000/-"
                                        >
                                            2 Month Rent ₹5000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹5500/-"
                                            value="3 Month Rent ₹5500/-"
                                        >
                                            3 Month Rent ₹5500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹7200/-"
                                            value="6 Month Rent ₹7200/-"
                                        >
                                            6 Month Rent ₹7200/-
                                        </MenuItem>
                                    </TextField>
                                )}

                                {need === "Rent" && typeOfApplianceToRent === "Refrigerator" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹1800/-"
                                            value="1 Month Rent ₹1800/-"
                                        >
                                            1 Month Rent ₹1800/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹3000/-"
                                            value="2 Month Rent ₹3000/-"
                                        >
                                            2 Month Rent ₹3000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹3500/-"
                                            value="3 Month Rent ₹3500/-"
                                        >
                                            3 Month Rent ₹3500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹5000/-"
                                            value="6 Month Rent ₹5000/-"
                                        >
                                            6 Month Rent ₹5000/-
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Rent" && typeOfApplianceToRent === "Washing Machine" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹2000/-"
                                            value="1 Month Rent ₹2000/-"
                                        >
                                            1 Month Rent ₹2000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹3500/-"
                                            value="2 Month Rent ₹3500/-"
                                        >
                                            2 Month Rent ₹3500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹4000/-"
                                            value="3 Month Rent ₹4000/-"
                                        >
                                            3 Month Rent ₹4000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹5000/-"
                                            value="6 Month Rent ₹5000/-"
                                        >
                                            6 Month Rent ₹5000/-
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Rent" && typeOfApplianceToRent === "Microwave" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹1500/-"
                                            value="1 Month Rent ₹1500/-"
                                        >
                                            1 Month Rent ₹1500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹2500/-"
                                            value="2 Month Rent ₹2500/-"
                                        >
                                            2 Month Rent ₹2500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹3500/-"
                                            value="3 Month Rent ₹3500/-"
                                        >
                                            3 Month Rent ₹3500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹4000/-"
                                            value="6 Month Rent ₹4000/-"
                                        >
                                            6 Month Rent ₹4000/-
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Rent" && typeOfApplianceToRent === "Ceiling Fan" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹550/-"
                                            value="1 Month Rent ₹550/-"
                                        >
                                            1 Month Rent ₹550/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹650/-"
                                            value="2 Month Rent ₹650/-"
                                        >
                                            2 Month Rent ₹650/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹750/-"
                                            value="3 Month Rent ₹750/-"
                                        >
                                            3 Month Rent ₹750/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹1000/-"
                                            value="6 Month Rent ₹1000/-"
                                        >
                                            6 Month Rent ₹1000/-
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Rent" && typeOfApplianceToRent === "Water Cooler" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select months to rent"
                                        fullWidth
                                        onChange={(e) => setApplianceRentDuration(e.target.value)}
                                        defaultValue=""
                                    >
                                        <MenuItem
                                            key="1 Month Rent ₹1500/-"
                                            value="1 Month Rent ₹1500/-"
                                        >
                                            1 Month Rent ₹1500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="2 Month Rent ₹2000/-"
                                            value="2 Month Rent ₹2000/-"
                                        >
                                            2 Month Rent ₹2000/-
                                        </MenuItem>
                                        <MenuItem
                                            key="3 Month Rent ₹2500/-"
                                            value="3 Month Rent ₹2500/-"
                                        >
                                            3 Month Rent ₹2500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="6 Month Rent ₹3500/-"
                                            value="6 Month Rent ₹3500/-"
                                        >
                                            6 Month Rent ₹3500/-
                                        </MenuItem>
                                        <MenuItem
                                            key="1 Year Rent ₹5000/-"
                                            value="1 Year Rent ₹5000/-"
                                        >
                                            1 Year Rent ₹5000/-
                                        </MenuItem>
                                    </TextField>
                                )}
                                {need === "Services" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select your required appliance"
                                        fullWidth
                                        defaultValue=""
                                        onChange={(e) => setSelectedAppliance(e.target.value)}
                                    >
                                        {categories.map((service) => (
                                            <MenuItem key={service.name} value={service.name}>
                                                {service.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                {need === "Services" &&
                                    selectedAppliance === "Air Conditioner" && (
                                        <>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Select type of AC"
                                                fullWidth
                                                defaultValue=""
                                                onChange={(e) => setTypeOfApplianceToRent(e.target.value)}
                                            >
                                                <MenuItem key="split" value="Split AC">
                                                    Split AC
                                                </MenuItem>
                                                <MenuItem key="window" value="Window AC">
                                                    Window AC
                                                </MenuItem>
                                                <MenuItem key="cassette" value="Cassette AC">
                                                    Cassette AC
                                                </MenuItem>
                                            </TextField>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Select AC Capacity"
                                                fullWidth
                                                defaultValue=""
                                                onChange={(e) => setSelectedAcCapacity(e.target.value)}
                                            >
                                                <MenuItem key="1 Ton" value="1 Ton">
                                                    1 Ton
                                                </MenuItem>
                                                <MenuItem key="1.5 Ton" value="1.5 Ton">
                                                    1.5 Ton
                                                </MenuItem>
                                                <MenuItem key="2 Ton" value="2 Ton">
                                                    2 Ton
                                                </MenuItem>
                                            </TextField>
                                        </>
                                    )}
                                {need === "Services" && selectedAppliance !== "" && (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Select your required service"
                                        fullWidth
                                        defaultValue=""
                                        onChange={(e) => setSelectedService(e.target.value)}
                                    >
                                        {categories.map(
                                            (category) =>
                                                category.name === selectedAppliance &&
                                                category.services.map((category) => (
                                                    <MenuItem key={category} value={category}>
                                                        {category}
                                                    </MenuItem>
                                                ))
                                        )}
                                    </TextField>
                                )}
                                <div className={styles.flexRowCenter}>
                                    <Button
                                        variant={buttonVariant}
                                        type="submit"
                                        size="large"
                                        className={styles.modalButton}
                                    >
                                        Book Now
                                    </Button>
                                </div>
                            </form>
                        </Typography>
                    </Box>
                </Fade>

            </Modal>
            <Snackbar open={openToast} autoHideDuration={6000} onClose={handleToastClose}>
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                    Appointment Booked Successfully!
                </Alert>
            </Snackbar>
        </>
    );
}
