import React from 'react'
import styles from './ItemsContainer.module.css'

const ItemsContainer = (props) => {
    return (
        <div className={styles.cont}>
            <ul>
                {props.children}
            </ul>
        </div>
    )
}

export default ItemsContainer