import React from 'react'
import styles from './About.module.css'
import NavbarContained from '../../components/NavbarContained/NavbarContained'
import Footer from '../../components/Footer/Footer'
import Cart from '../../components/Cart/Cart'

const About = () => {
    return (
        <>
            <NavbarContained />
            <h1 className={styles.heading}>About Us</h1>
            <div className={styles.para}>
                <h2 className={styles.heading2}>Our Mission</h2>
                <p>
                    At Direct Repair Services, we're not just another on-demand service platform; we're a technology-driven solution that's here to make your life easier and more efficient. Our mission is to connect you with a wide range of services right in the comfort of your own home, whenever you need them. From the smallest fix to the grandest renovation, we've got you covered.
                </p>
                <p>
                    What sets us apart from the rest is our commitment to delivering top-notch service in a standardized and reliable manner. We understand that you lead a busy life, and finding trusted service professionals can be a hassle. That's where we come in. With just a few clicks, you can book a variety of services, including:
                </p>
                <ul>
                    <li>AC Repair</li>
                    <li>Refrigerator Repair</li>
                    <li>Washing Machine Repair</li>
                    <li>Microwave Repair</li>
                    <li>Geyser Repair</li>
                    <li>RO Repair</li>
                    <li>Other Repair</li>
                </ul>
                <p>
                    We're here to help you with all of your home maintenance needs, and we're committed to providing you with the best service possible. Our team of experts is always ready to answer any questions you may have, so don't hesitate to reach out!
                </p>
                <p>
                    Thanks being a part of the Direct Repair Services family, and we look forward to serving you soon!

                </p>
            </div>
            <Cart /><Footer />
        </>
    )
}

export default About