import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { db } from "../../../firebase";
import styles from "./Messages.module.css";
import { Link } from "react-router-dom";
import NavbarContained from "../../../components/NavbarContained/NavbarContained";
import NavbarMessages from "../../../components/NavbarMessages/NavbarMessages";
import { Button } from "@mui/material";

const Messages = () => {
    const [messages, setMessages] = React.useState([]);

    function sortMessagesByDateAndTime(messages) {
        // Sort the messages array in descending order based on date and time
        return messages.sort((a, b) => {
            const dateA = new Date(`${a.date} ${a.time}`);
            const dateB = new Date(`${b.date} ${b.time}`);

            if (dateA > dateB) {
                return -1; // Return -1 to place 'a' before 'b'
            } else if (dateA < dateB) {
                return 1; // Return 1 to place 'b' before 'a'
            } else {
                // If dates are equal, compare times
                const timeA = new Date(`1970-01-01 ${a.time}`);
                const timeB = new Date(`1970-01-01 ${b.time}`);
                return timeA > timeB ? -1 : timeA < timeB ? 1 : 0;
            }
        });
    }

    const fetchMessages = async () => {
        const messagesCollection = collection(db, "messages");
        const messages = await getDocs(messagesCollection);
        const messageArray = messages.docs.map((doc) => doc.data());
        sortMessagesByDateAndTime(messageArray);
        setMessages(messageArray);
    };

    useEffect(() => {
        fetchMessages();
    }, []);

    const markMessageAsSolved = async (message) => {
        const messageDocRef = doc(db, "messages", message.id);
        await updateDoc(messageDocRef, { solved: true });
        fetchMessages();
    };
    const markMessageAsPending = async (message) => {
        const messageDocRef = doc(db, "messages", message.id);
        await updateDoc(messageDocRef, { solved: false });
        fetchMessages();
    };

    const handleClickPhoneNumber = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`);
    };
    const refreshHandler = () => {
        window.location.reload()
    }
    return (
        <>
            <div>
                <NavbarMessages />
                <br />
                <Button variant='contained' fullWidth color="secondary" onClick={refreshHandler}>Refresh</Button>

            </div>
            <ul className={styles.ul}>
                {messages.map((message) => (
                    <li
                        key={message.id}
                        className={message.solved ? styles.solved : styles.pending}
                    >
                        <div>
                            <span className={styles.field}>Name:</span> {message.name}
                        </div>
                        <div>
                            <span className={styles.field}>Address:</span>{" "}
                            <span>{message.address}</span>
                        </div>
                        <div>
                            <span className={styles.field}>Mobile Number:</span>{" "}
                            <span>{message.mobileNumber}</span>
                            <Link
                                className={styles.callLink}
                                to={`tel:${message.mobileNumber}`}
                                onClick={() => handleClickPhoneNumber(message.mobileNumber)}
                            >
                                Call
                            </Link>
                        </div>
                        <div>
                            <span className={styles.field}>Selected Service:</span>{" "}
                            <span>{message.selectedService}</span>
                        </div>
                        <div>
                            <span className={styles.field}>Need:</span>{" "}
                            <span>{message.need}</span>
                        </div>
                        <div>
                            <span className={styles.field}>Selected Appliance:</span>{" "}
                            <span>{message.selectedAppliance}</span>
                        </div>
                        <div>
                            <span className={styles.field}>Date:</span>{" "}
                            <span>{message.date}</span>
                        </div>
                        <div>
                            <span className={styles.field}>Time:</span>{" "}
                            <span>{message.time}</span>
                        </div>
                        <div>
                            <span className={styles.field}>Status:</span>{" "}
                            {message.solved ? <span>SOLVED</span> : <span>PENDING</span>}
                        </div>
                        <div>
                            {!message.solved && (
                                <button
                                    className={styles.Solvebutton}
                                    onClick={() => markMessageAsSolved(message)}
                                >
                                    Mark as Solved
                                </button>
                            )}
                            {message.solved && (
                                <button
                                    className={styles.Pendingbutton}
                                    onClick={() => markMessageAsPending(message)}
                                >
                                    Mark as Pending
                                </button>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default Messages;
